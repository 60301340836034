import firebaseConfig from "./config";

class Firebase {
  constructor(app) {
    if (!firebaseInstance) {
      app.initializeApp(firebaseConfig);

      this.auth = app.auth();
      this.db = app.firestore();
      this.functions = app.functions();
      this.storage = app.storage();
    }
  }

  async login({ email, password }) {
    try {
      const loginResponse = await this.auth.signInWithEmailAndPassword(
        email,
        password
      );
      return loginResponse;
    } catch (err) {
      console.log("error!", err);
    }
  }

  async logout() {
    try {
      await this.auth.signOut();
    } catch (err) {
      console.log("error!", err);
    }
  }
}

let firebaseInstance;

function getFirebaseInstance(app) {
  if (!firebaseInstance && app) {
    firebaseInstance = new Firebase(app);
    return firebaseInstance;
  } else if (firebaseInstance) {
    return firebaseInstance;
  } else {
    return null;
  }
}

export default getFirebaseInstance;
