/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
// import "./node_modules/bootstrap/dist/css/bootstrap.css";
// import "./node_modules/wmk-lib/dist/index.css";

const React = require("react");
const Layout = require("./src/components/layout/MainLayout").default;

require("./node_modules/bootstrap/dist/css/bootstrap.css");
require("./node_modules/wmk-lib/dist/index.css");
require("./src/fonts/din-alternate-bold-web/stylesheet.css")

exports.wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>;
};
