/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import { MainLayout, Sticky } from "wmk-lib";
import styled from "styled-components";
import { PortalFooter, PortalHeader } from "../common/ntmwd/";
import { FirebaseContext, useAuth } from "../Firebase/";

const Styled = {};
Styled.Global = styled.div`
  font-family: "Open Sans", sans-serif;
  .din {
    font-family: "DIN Alternate", sans-serif;
    font-weight: bold;
  }
  overflow: hidden;
`;

const Layout = ({ children }) => {
  const { user, firebase, loading } = useAuth();
  return (
    <FirebaseContext.Provider value={{ user, firebase, loading }}>
      <Styled.Global>
        <MainLayout
          Header={() => (
            <Sticky>
              <PortalHeader />
            </Sticky>
          )}
          Footer={PortalFooter}
        >
          {children}
        </MainLayout>
      </Styled.Global>
    </FirebaseContext.Provider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  pageTitle: PropTypes.string,
};

Layout.defaultProps = {
  pageTitle: "Board Portal",
};

export default Layout;
