import React, { useContext } from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { HeaderLogo } from "./HeaderLogo";
import { FirebaseContext } from "../../../Firebase";
import { navigate } from "gatsby";
import { WmkLink } from "wmk-link";
import { colors } from "../../../../vars/palette";
import Theme from "../../../../vars/ThemeOptions";

const PortalTopBar = () => {
  const { user, firebase } = useContext(FirebaseContext);
  const handleLogout = () => {
    firebase.logout().then(() => navigate("/login"));
  };
  return (
    <Styled.TopBar className="g-0">
      <Container>
        <Row style={{ minHeight: "38px" }}>
          <Styled.Logo xs={6} sm={3}>
            <HeaderLogo />
          </Styled.Logo>
          <Col
            xs="auto"
            lg={6}
            style={{
              textAlign: "center",
              padding: "1vh 3vh",
              width: user ? "auto" : "100%",
            }}
            className="tagline d-none d-sm-block"
          >
            Regional Service Through Unity
            <span>… Meeting our Region’s Needs Today and Tomorrow</span>
          </Col>

          {user && (
            <Col xs={6} sm={3}>
              <Row style={{ height: "100%" }}>
                <Styled.Link>
                  <WmkLink
                    to="https://www.ntmwd.com/login/"
                    target="blank"
                    label="Member Portal"
                  >
                    <span
                      style={{
                        fontSize: ".8rem",
                        textTransform: "uppercase",
                        lineHeight: 1,
                      }}
                    >
                      <span className="d-block d-lg-none">Members</span>
                      <span className="d-none d-lg-block">Member Portal</span>
                    </span>
                  </WmkLink>
                </Styled.Link>
                <Styled.Link className="alt">
                  <button onClick={handleLogout} aria-label="Logout">
                    <span
                      style={{
                        fontSize: ".8rem",
                        textTransform: "uppercase",
                        lineHeight: 1,
                      }}
                    >
                      Logout
                    </span>
                  </button>
                </Styled.Link>
              </Row>
            </Col>
          )}
        </Row>
      </Container>
    </Styled.TopBar>
  );
};

export default PortalTopBar;

const Styled = {};
Styled.TopBar = styled(Row)`
  background-color: ${colors.hex("primary")};
  box-shadow: 2px 3px 6px rgba(0, 0, 0, 0.25);
  color: ${colors.hex("white")};
  .col {
    padding: 0;
  }
  .tagline {
    font-size: 0.8rem;
    @media screen and (max-width: ${Theme.break.md}px) {
      span {
        display: none;
      }
    }
  }
  a {
    text-decoration: none;
  }
`;
Styled.Logo = styled(Col)`
  position: relative;
`;
Styled.Link = styled(Col)`
&.alt {
  background-color: ${colors.hex("secondary")};
  button, .wmk-link {
    background-color: ${colors.hex("secondary")};
  }
}
background-color: ${colors.hex("tertiary")};
height: auto;
margin: 0 .5vh;
button, .wmk-link {
  box-shadow: inset 0 0 3px rgba(0,0,0,.15);
  color: white;
  text-transform: uppercase:
  font-size: .8rem;
  appearance: none;
  border: none;
  height: auto;
  padding: .25vh .3vh;
  &:hover {
    text-decoration: none;
  }
    display: flex;
    width: 100%;
    background: ${colors.hex("tertiary")};
    height: 100%;
    justify-content: center;
    align-items: center;
    transition: all .3s ease;
  
  &:hover {
      text-decoration: none;
      background-color: ${colors.hex("accent")};
      color: white;
      ransition: all .3s ease;
  }
}
`;
