import React from "react";
import CSS from "csstype";

export interface HeadingProps {
  children: React.ReactNode;
  din?: boolean;
  style?: CSS.Properties;
}

export const Heading4 = ({ children, din, style }: HeadingProps) => {
  return (
    <h4 style={style} className={din ? "din" : undefined}>
      {children}
    </h4>
  );
};
