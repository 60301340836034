import React, { useRef, useState, useEffect } from "react";
import { NTMWDLogo } from "../";
import styled from "styled-components";
import { WmkLink } from "wmk-link";

const Styled = {};
Styled.Logo = styled.div`
  height: calc(${({ size }) => size.height}px);
  width: calc(1vh + ${({ size }) => size.width}px);
  background: white;
  position: absolute;
  border-bottom-left-radius: 1vh;
  border-bottom-right-radius: 1vh;
  box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.33);
  svg {
    padding: 0.25vh;
    transform: scale(0.75);
    position: relative;
    right: 0.5vh;
    bottom: 0.5vh;
  }
`;

export const HeaderLogo = () => {
  const [logoSizes, setLogoSizes] = useState(0);
  const upOnce = true;

  const logoRef = useRef();

  useEffect(() => {
    const bBox = logoRef.current.getBBox();
    setLogoSizes({ height: bBox.height, width: bBox.width });
  }, [upOnce]);
  return (
    <Styled.Logo size={logoSizes}>
      <WmkLink to="/">
        <NTMWDLogo ref={logoRef} />
      </WmkLink>
    </Styled.Logo>
  );
};
