const firebaseConfig = {
  apiKey: "AIzaSyAP1U4ioe2FStQIn3bX23LVCqVcmFEZzes",
  authDomain: "ntmwd-board-portal.firebaseapp.com",
  databaseURL: "https://ntmwd-board-portal.firebaseio.com",
  projectId: "ntmwd-board-portal",
  storageBucket: "ntmwd-board-portal.appspot.com",
  messagingSenderId: "29414917862",
  appId: "1:29414917862:web:2da3b5227474c794f81d27",
};

export default firebaseConfig;
